import React, { FunctionComponent } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { SetToolingItemDto, SetUnitDto } from '../../api/types/Sets/SetDto'
import {
  Grid, List, ListItem, Checkbox, Stack, Typography, Box, listItemClasses, IconButton, ListItemButton,
} from '@mui/material'
import { processToolingCheck } from '../../store/sets/currentItemSlice'
import { UnitElementId, ElementIdIndicator } from '../tooling/components/UnitIndicator'
import UpdateUnitToolingIcon from './UpdateUnitToolingIcon'
import { Link } from 'react-router-dom'

interface Props {
  assemblyUnits?: SetUnitDto[];
  tooling?: SetToolingItemDto [];
  editTooling?: SetToolingItemDto [];
  disabled?: boolean;
  canEdit?: boolean;
  onCheckTooling: (tooling: SetToolingItemDto) => void;
}

const selectUnitTooling = (unitFileId: number, unitsTooling: SetToolingItemDto []): SetToolingItemDto[] => {
  return unitsTooling
    .filter(t => t.units.map(u => u.fileId).includes(unitFileId))
}

const SetUnitTooling: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch()
  const getSelectedUnitIds = (assemblyUnit: SetUnitDto, tooling: SetToolingItemDto): boolean => {
    const allUnit: number[] = []
    if (props.editTooling) {
      props.editTooling.map(t => {
        t.units.forEach(u => {
          if (!allUnit.includes(u.fileId)) {
            allUnit.push(u.fileId)
          }
        })
      })
      if (allUnit.includes(assemblyUnit.fileId)) {
        return !props.editTooling.includes(tooling)
      }
    }
    return false
  }

  const handleCheck = (tooling: SetToolingItemDto) => () => {
    dispatch(processToolingCheck(tooling))
  }

  const handleDisable = (toggledTooling: SetToolingItemDto) => {
    if (props.editTooling) {
      if (props.editTooling.length < 2) {
        return props.editTooling[0].toolingNumber === toggledTooling.toolingNumber
      }
    }
  }

  return (
    <Box m={5}>
      <Grid container direction="column" spacing={4}>
        <Grid mb={3}>
          <Typography sx={{ fontWeight: 600 }}>Units tooling bom</Typography>
        </Grid>
        <Grid>
          {props.assemblyUnits && props.assemblyUnits.map(unit => <Grid item>
            <Stack direction="row" spacing={3} alignItems="center">
              <ElementIdIndicator variant={'inherit'}/>
              <Typography sx={{ fontWeight: 400 }}>{unit.elementId}</Typography>
            </Stack>
            <Stack direction="row" spacing={3} alignItems="center">
              <Typography variant="caption" sx={{ fontWeight: 400 }}>{unit.elementName}</Typography>
              {props.canEdit && <UpdateUnitToolingIcon unitFileId={unit.fileId}/>}
            </Stack>
            <List
              sx={{
                [`& .${listItemClasses.root}:hover`]: {
                  backgroundColor: (theme) => theme.palette.action.hover,
                },
              }}
            >
              {props.tooling && selectUnitTooling(unit.fileId, props.tooling)
                .map((t) => {
                  const labelId = `unit-tooling-bom-label-${t.toolingNumber}`
                  return (
                    <ListItem
                      key={t.toolingNumber}
                      divider
                      secondaryAction={props.canEdit && <Checkbox
                        edge="end"
                        onChange={handleCheck(t)}
                        inputProps={{ 'aria-labelledby': labelId }}
                        checked={props.editTooling && props.editTooling.includes(t)}
                        disabled={handleDisable(t) ||
                          getSelectedUnitIds(unit, t) ||
                          props.disabled}
                      />
                      }
                    >
                      <ListItemButton component={Link}
                                      to={`/tooling/${t.toolingId}`}
                                      dense>
                        <Stack spacing={5} direction={'row'}>
                          <Typography sx={{ fontWeight: 500 }}>{t.toolingNumber}</Typography>
                          <Typography color="GrayText" sx={{ fontWeight: 400 }}>{t.status}</Typography>
                          {
                            t.location?.atSupplier &&
                            <Typography color="GrayText"
                                        sx={{ fontWeight: 400 }}>{t.location?.atSupplier.name}</Typography>
                          }
                          {
                            t.location?.atStock &&
                            <Typography color="GrayText"
                                        sx={{ fontWeight: 400 }}>{t.location?.atStock.name}</Typography>
                          }

                        </Stack>
                      </ListItemButton>

                    </ListItem>
                  )
                })
              }
            </List>
          </Grid>)}
        </Grid>
      </Grid>
    </Box>
  )
}

export default SetUnitTooling
