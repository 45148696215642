import React, { Fragment, FunctionComponent, useState } from 'react'
import { SearchUnit } from '../../../store/types/searchUnit'
import { ToolingUnitDto } from '../../../api/types/UnitToolingDto'
import { Grid, Typography, Stack, List, listItemClasses, ListItem, Checkbox, Button } from '@mui/material'
import { UnitElementId, UnitProductionId } from '../../tooling/components/UnitIndicator'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  processSelectedToolingUnit, getAssemblyUnitBom, getAssemblyUnitTooling, processCreatedTooling,
} from '../../../store/sets/createNewSetDialogSlice'
import { checkPermission } from '../../../store/user/userSlice'
import { Permissions_Tooling_Edit } from '../../../api/types/permissions'
import CreateNewToolingFromSetDialog
  from '../../tooling/components/CreateNewToolingDialog/CreateNewToolingFromSetDialog'
import { SupplierDto } from '../../../api/types/SupplierData'

interface Props {
  selectedUnit?: SearchUnit
  assemblyUnits?: SearchUnit []
  selectedSupplier: SupplierDto | null
  availableUnitsTooling?: ToolingUnitDto []
  selectedUnitsTooling: ToolingUnitDto []
}

const checkUnitToolingExist = (unitFileId: number, unitsTooling: ToolingUnitDto []): boolean => {
  return unitsTooling
    .filter(t => t.unitFileId === unitFileId).length > 0
}

const getUnitTooling = (unitFileId: number, unitsTooling: ToolingUnitDto []): ToolingUnitDto [] => {
  return unitsTooling
    .filter(t => t.unitFileId === unitFileId)
}

const checkUnitToolingSelected = (unitTooling: ToolingUnitDto, unitsTooling: ToolingUnitDto []): boolean => {
  const isExistUnit = unitsTooling.some(t => t.unitFileId === unitTooling.unitFileId)
  const isCurrentSelected = unitsTooling.some(t => t.tooling.toolingNumber === unitTooling.tooling.toolingNumber)
  if (isCurrentSelected) {
    return false
  }
  return isExistUnit
}

const SetUnitsTooling: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch()
  const canEditTooling = useAppSelector((root) => checkPermission(root, Permissions_Tooling_Edit))
  const [isOpenCreateToolingNewDialog, setIsOpenCreateToolingNewDialog] = useState(false)
  const [toolingUnit, setToolingUnit] = useState<SearchUnit | undefined>(undefined)

  const handleCheck = (toolingUnit: ToolingUnitDto) => () => {
    dispatch(processSelectedToolingUnit(toolingUnit))
  }

  const handleCreateNew = () => {
    setIsOpenCreateToolingNewDialog(true)
  }

  return (
    <Fragment>
      <Grid container direction="column" spacing={4}>
        {props.assemblyUnits
          && props.assemblyUnits
                  .map((unit) =>
                         <Grid item>
                           <Typography
                             sx={{ fontWeight: 600 }}>{unit.elementName}</Typography>
                           <Stack direction="row" spacing={3} alignItems="center">
                             <UnitElementId elementId={unit.elementId}/>
                             <UnitProductionId
                               productionId={unit.productionId}/>
                           </Stack>
                           <List
                             sx={{
                               [`& .${listItemClasses.root}:hover`]: {
                                 backgroundColor: (theme) => theme.palette.action.hover,
                               },
                             }}
                           >
                             {props.availableUnitsTooling
                              && checkUnitToolingExist(unit.unitFileId, props.availableUnitsTooling) ?
                              getUnitTooling(unit.unitFileId, props.availableUnitsTooling)
                                .map((t) => {
                                  const labelId = `tooling-list-label-${t.tooling.toolingNumber}`
                                  return (
                                    <ListItem
                                      key={t.tooling.toolingNumber}
                                      divider
                                      secondaryAction={
                                        <Checkbox
                                          edge="end"
                                          onChange={handleCheck(t)}
                                          inputProps={{ 'aria-labelledby': labelId }}
                                          checked={props.selectedUnitsTooling.map(t => t.tooling.id)
                                                        .some(id => id === t.tooling.id)}
                                          disabled={checkUnitToolingSelected(t, props.selectedUnitsTooling)}
                                        />
                                      }
                                    >
                                      <Stack spacing={3} direction={'row'}>
                                        <Typography sx={{ fontWeight: 600 }}>{t.tooling.toolingNumber}</Typography>
                                        <Typography color="GrayText">{t.tooling.status.name}</Typography>
                                        {
                                          t.tooling.location?.atSupplier &&
                                          <Typography
                                            color="GrayText">{t.tooling.location?.atSupplier.name}</Typography>
                                        }
                                        {
                                          t.tooling.location?.atStock &&
                                          <Typography color="GrayText">{t.tooling.location?.atStock.name}</Typography>
                                        }
                                      </Stack>
                                    </ListItem>
                                  )
                                }) :
                              <ListItem
                                key={unit.unitFileId}
                                secondaryAction={
                                  <>
                                    {canEditTooling &&
                                      <Grid item>
                                        <Button variant="text" onClick={() => {
                                          setToolingUnit(unit)
                                          handleCreateNew()
                                        }}>
                                          Create new
                                        </Button>
                                      </Grid>
                                    }
                                  </>
                                }
                                divider
                              >
                                <Stack spacing={1}>
                                  <Typography sx={{ fontWeight: 600 }}>No tooling</Typography>
                                </Stack>
                              </ListItem>
                             }
                           </List>
                         </Grid>)}

      </Grid>
      <CreateNewToolingFromSetDialog
        open={isOpenCreateToolingNewDialog}
        unit={toolingUnit}
        supplier={props.selectedSupplier}
        onClose={(tooling) => {
          setIsOpenCreateToolingNewDialog(false)
          if (props.selectedUnit) {
            dispatch(getAssemblyUnitBom({ unitFileId: props.selectedUnit?.unitFileId }))
            dispatch(getAssemblyUnitTooling({ unitFileId: props.selectedUnit?.unitFileId }))
              .then(() => {
                dispatch(processCreatedTooling(tooling))
              })
          }
        }}
      />
    </Fragment>
  )
}

export default SetUnitsTooling
